<template>
  <div class="container-fliud">
    <Header></Header>
<div class="faqInfo">

<div class="container">
<div class="book">
<h4 style="margin-bottom:50px">{{elibaries.title}}</h4>
<div class="row" >
<div class="col-md-4">
    <img :src='elibrayUrl+elibaries.imageUrl' alt="" style="width:300px; margin-right:100px">
    <!-- <embed :src='elibrayUrl+elibaries.imageUrl' width="200px" height="200px" /> -->
</div>
<div class="col-md-8">
    <table class="table">
        <thead>
            <tr>
            <th  style="color:#97c149">Author:</th>
            <th>{{elibaries.author}}</th>
            </tr>
        </thead>
        <tbody>
           <tr>
            <td  style="color:#97c149">Publisher</td>
            <td >INBAR</td>
            </tr>
            <tr>
            <td style="color:#97c149">Year</td>
            <td>{{elibaries.year}}</td>
            </tr>
            <tr>
            <td>{{elibaries.description}}</td>
            </tr>
        </tbody>
    </table>

    <a :href="elibrayUrl+elibaries.publicationUrl"  target="_blank" style="background-color:#97c149; border:#97c149; width:200px" class="btn btn-primary">download</a>

</div>

</div>

</div>
</div>

</div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl'
import appConfig from "../appConfig";

export default {
  name: 'Home',
  components: {
    Header,
   Footer
  },
  data() {
      return {
          elibaries:{},
          elibrayUrl: appConfig.baseUrl,
          error:{}
      }
  },
   methods: {
      pubication(){
         baseUrl.get('/api/homepage/getFeaturedPublication')
          .then((res)=>{
               this.elibaries = res.data
           })
           .catch((err)=>{
               this.error = err
           })
       }
   },
   mounted() {
      this.pubication()
   }
}
</script>
<style scoped>
.faqInfo{
margin-top: 200px;
}
#accordion{
padding: 100px;
}
.faq{
padding-left: 100px;
margin: 20px;
}
/* .hr{
padding-left: 100px;
} */
.gray{
background-color: #F5F7FA;
padding: 60px;
}
.gray button{
background-color: #97c149;
border: #97c149;
padding: 15px;
}
#inputSearch{
width: 500px;
padding: 26px;
}
select{
width: 200px;
padding: 16px;
}
.scrum{
display: flex;
}
p{
margin: 5px;
font-size: bolder;
}
.book{
padding: 70px;
}
#bookInfo{
color: rgb(146, 146, 146);
}
.img{
width: 250px;
height: 200px;
object-fit: contain;
}
#checkbox{
font-size: 35px;
padding: 20px;
}
#download{
background-color: rgb(255, 255, 255);
border: 1px solid rgb(167, 164, 164);
color: rgb(167, 164, 164);
}
#row{
background-color: #F5F7FA;
padding: 10px;
margin-bottom: 20px;
}
</style>